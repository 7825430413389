body {
  font-family: var(--default-font), sans-serif;
  line-height: 1.5;
  box-sizing: border-box;
  margin: 0;
  font-size: 16px;
  max-width: 100vw;
  overflow-x: hidden !important;
  background-color: var(--background-primary);
  font-weight: 500;
  letter-spacing: 0.01em;
  font-weight: normal;
  color: var(--text-primary);
}

h1,
h2,
h3 {
  font-weight: normal;
}

p,
a {
  color: var(--text-primary);
  margin: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input {
  font-family: var(--default-font), sans-serif;
  font-weight: 500;
  font-size: 16px;
}

input:focus {
  outline: unset;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="range"],
input[type="range"]::-webkit-slider-thumb,
input[type="range"]::-webkit-media-slider-thumb,
input[type="range"]::-webkit-slider-runnable-track {
  appearance: none;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  box-shadow: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="range"]:focus {
  outline: none;
}

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

html,
body {
  overscroll-behavior: none;
}
