html body {
  /* background: var(--background-accent-secondary, #defef9); */
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  background: transparent;
}

img {
  display: block;
}
